































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import WishListApi from "@/api/wish-list.api";
import { countdown, monthday, observer } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { TYPE_TEXT } from "@/constant";
import BlindBoxItemBox from "@/views/wish-list/components/BlindBoxItemBox.vue";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { Toast } from "vant";
import moment from "moment";

@Component({
  components: {
    BlindBoxItemBox,
  },
})
export default class WishList extends Vue {
  globalState = getModule(GlobalStateStore);
  app = InteractionLib.isApp();
  mobile = InteractionLib.mobile;
  openView = false;

  get welfareList() {
    return this.WishListData.welfare_list.find(Boolean) || {};
  }

  openApp() {
    if (this.mobile.wechat) {
      this.openView = true;
      return;
    }
    let openAppURLAndroid = `gamepower://com.vgn.steampro/home?codeConductFlutter={"router":"/web_public","arguments":{"url":"${window.location.href}"}}`;
    let openAppURLIos = `https://api.vgn.cn/flutter/home?codeConductFlutter={"router":"/web_public","arguments":{"url":"${window.location.href}"}}`;
    Toast.loading({ duration: 3000 });
    window.location.href = this.mobile.android
      ? openAppURLAndroid
      : openAppURLIos;
    setTimeout(() => {
      window.location.href = this.mobile.android
        ? "https://sj.qq.com/myapp/detail.htm?apkName=com.vgn.steampro"
        : "https://apps.apple.com/cn/app/id1571680401";
    }, 3000);
  }

  get isPast() {
    return moment().unix() > this.welfareList.failure_time;
  }

  get TagList() {
    let arr = this.WishListData.link_game_genres;
    arr = arr.map((item: any) => {
      if (!item) {
        return null;
      }
      if (item.length > 4) {
        return item.slice(0, 4) + "..";
      }
      return item;
    });
    arr = arr.filter((res: any) => {
      if (res) {
        return res;
      }
    });
    if (arr.length > 3) {
      return arr.splice(0, 3);
    }
    return arr;
  }

  get heightSystem() {
    const { clientWidth, offsetWidth } = document.body;
    //0.682
    return clientWidth;
  }

  get characterText(): string {
    return TYPE_TEXT[this.welfareList.character] ?? "";
  }

  get entity(): boolean {
    return false;
  }

  get GameName() {
    if (this.WishListData.link_game_name.length > 8) {
      return this.WishListData.link_game_name.slice(0, 8) + "...";
    }
    return this.WishListData.link_game_name.slice(0, 8);
  }

  toBehaviorData() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(this.WishListData.behavior_data);
    }
    window.location.href = this.WishListData.behavior_data;
  }

  toMysupply() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/mysupply`);
    }
    this.$router.push({ name: "mysupply" });
  }

  WishListData: any = null;
  couponTimer: any = null;
  couponWord: any = null;
  activeFlag: any = true;

  async init() {
    this.WishListData = await WishListApi.getWishList(this.$route.params.aid);
    this.couponTimer = setInterval(() => {
      if (this.welfareList.status) {
        return "";
      }
      if (!this.welfareList.failure_time || this.isPast) {
        return (this.couponWord = "已失效");
      }

      const { day, hour, minute, second } = countdown(
        this.welfareList.failure_time
      );
      if (
        Number(day) == 0 &&
        Number(hour) == 0 &&
        Number(minute) == 0 &&
        Number(second) == 0
      ) {
        clearInterval(this.couponTimer);
        return (this.couponWord = "已失效");
      }
      this.couponWord = `剩余${day}天 ${hour}:${minute}:${second}`;
    }, 1000);
    if (
      this.WishListData.isJoin &&
      (!this.WishListData?.welfare_list ||
        !this.WishListData?.welfare_list.length)
    ) {
      this.toBehaviorData();
    }
  }

  handleOpenBlindBox(): void {
    const { target } = this.welfareList;
    if (this.welfareList.couponType == 0) {
      //商品优惠卷
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${location.origin}/sp-store/${target}`
        );
      }
      location.href = `${location.origin}/sp-store/${target}`;
    } else {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${location.origin}/blind-box/${target}`
        );
      }
      location.href = `${location.origin}/blind-box/${target}`;
    }
  }

  get versionCode() {
    return this.globalState.versionCode;
  }

  toCheckGame() {
    if (this.versionCode >= "2.1.3") {
      return InteractionLib.router(
        19,
        JSON.stringify({
          id: this.WishListData.product_id,
        })
      );
    }
    Toast("请升级至最新版App");
  }

  get endTime() {
    const { year, day, month, hour, min, sec } = monthday(
      this.WishListData.end_time
    );

    return `${year}-${month}-${day} ${hour}:${min}:${sec} 结束`;
  }

  timer: any = null;

  mounted() {
    observer("refreshInit", async () => {
      this.init();
    });
    InteractionLib.isWishList();
  }

  get endCouponTime() {
    const { year, day, month, hour, min, sec } = monthday(
      this.welfareList.failure_time
    );

    return `截止日期 ${year}-${month}-${day} ${hour}:${min}:${sec}`;
  }

  get rootStyle(): Record<string, string> {
    const { character } = this.welfareList;
    return {
      "--characterColor": `var(--card-color-${character})`,
    };
  }

  async created() {
    this.globalState.getUserInfo();
    document.body.title = "愿望单活动";
    await this.init();
    this.timer = setInterval(() => {
      if (!this.WishListData.end_time) return;
      const { day, hour, minute, second } = countdown(
        this.WishListData.end_time
      );

      if (
        Number(day) == 0 &&
        Number(hour) == 0 &&
        Number(minute) == 0 &&
        Number(second) == 0
      ) {
        this.activeFlag = false;
        this.init();
        clearInterval(this.timer);
      }
    }, 1000);
  }

  async handlerGet() {
    if (this.mobile.wechat) {
      this.openView = true;
      return;
    }
    if (!InteractionLib.isApp()) {
      return this.openApp();
    }
    if (this.versionCode < "2.1.3") {
      return Toast("请升级至最新版App");
    }
    if (!this.WishListData.limit_count) {
      return InteractionLib.handlerGet(
        this.WishListData.link_game_id,
        Number(this.$route.params.aid)
      );
    }
    await this.init();
    if (this.WishListData.currentJoinCount >= this.WishListData.limit_count) {
      this.activeFlag = false;
      return;
    }
    InteractionLib.handlerGet(
      this.WishListData.link_game_id,
      Number(this.$route.params.aid)
    );
  }

  toFreeRule() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        "https://support.qq.com/products/373434/faqs/116550"
      );
    }
    location.href = "https://support.qq.com/products/373434/faqs/116550";
  }
}
